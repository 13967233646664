<template>
  <div
    class="fixed bottom-0 right-0 z-30 flex w-full items-center justify-between gap-3 bg-gray-100 px-6 pb-2 pt-3"
  >
    <BaseLink
      :to="homePageLink"
      class="grid justify-items-center text-center"
      :aria-label="t('main.pageName')"
      :class="classes"
    >
      <home-icon />
      <span class="text-xs font-light">{{ t("main.pageName") }}</span>
    </BaseLink>
    <BaseLink
      :to="localePath('/catalog')"
      class="grid justify-items-center text-center"
      :aria-label="t('catalog.pageName')"
      :class="classes"
    >
      <catalog-icon />
      <span class="text-xs font-light">{{ t("catalog.pageName") }}</span>
    </BaseLink>
    <BaseLink
      :to="localePath('/souvenirs')"
      class="grid justify-items-center text-center"
      :aria-label="t('souvenirs.pageName')"
      :class="classes"
    >
      <souvenirs-icon />
      <span class="text-xs font-light">{{ t("souvenirs.pageName") }}</span>
    </BaseLink>
    <BaseLink
      :to="localePath('/basket')"
      class="relative grid justify-items-center text-center"
      :class="classes"
    >
      <div class="relative">
        <div
          class="absolute -right-2 -top-1 rounded-full bg-red-800 px-1.5 text-xs text-gray-50"
        >
          {{ basketStore.getBasketTotalQuantity }}
        </div>
        <cart-icon />
      </div>
      <span class="text-xs font-light">{{ t("basket-text") }}</span>
    </BaseLink>
    <!--    <div-->
    <!--      class="grid justify-items-center"-->
    <!--      @click="emit('show-city-modal', true)"-->
    <!--      v-if="locale === 'ru'"-->
    <!--    >-->
    <!--      <location-icon />-->
    <!--    </div>-->
  </div>
</template>
<i18n lang="json" src="@/translations/components/breadcrumbs.json"></i18n>
<i18n
  lang="json"
  src="@/translations/components/top-navigation/static.json"
></i18n>
<script lang="ts" setup>
import CartIcon from "@/components/base/icon/icons/CartIcon.vue";
import CatalogIcon from "@/components/base/icon/icons/CatalogIcon.vue";
import HomeIcon from "@/components/base/icon/icons/HomeIcon.vue";
import BaseLink from "@/components/base/link/BaseLink.vue";

import { useI18n, useLocalePath } from "@/.nuxt/imports";
import { useCitiesStore } from "../../../store/cities";
import { useBasketStore } from "../../../store/basket";
import SouvenirsIcon from "../../base/icon/icons/SouvenirsIcon.vue";

const { locale, t } = useI18n();
const localePath = useLocalePath();
const citiesStore = useCitiesStore();
const basketStore = useBasketStore();

const homePageLink = computed(() => {
  if (
    !citiesStore.getCurrentCity ||
    citiesStore.getCurrentCity.name === "Ижевск"
  ) {
    return localePath("/");
  }

  return localePath("/cities/" + citiesStore.getCurrentCity.slug);
});
const classes = computed(() => {
  if (locale.value === "zh") {
    return "gap-1";
  }
  return "";
});
</script>
