<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.338 0.833116C12.9707 0.502553 12.4941 0.319641 12 0.319641C11.5059 0.319641 11.0293 0.502553 10.662 0.833116L0 10.4291V20.8291C0 21.6778 0.337142 22.4917 0.937258 23.0919C1.53737 23.692 2.35131 24.0291 3.2 24.0291H20.8C21.6487 24.0291 22.4626 23.692 23.0627 23.0919C23.6629 22.4917 24 21.6778 24 20.8291V10.4291L13.338 0.833116ZM15 22.0261H9V17.0001C9 16.2045 9.31607 15.4414 9.87868 14.8788C10.4413 14.3162 11.2044 14.0001 12 14.0001C12.7956 14.0001 13.5587 14.3162 14.1213 14.8788C14.6839 15.4414 15 16.2045 15 17.0001V22.0261ZM22 20.8261C22 21.1444 21.8736 21.4496 21.6485 21.6746C21.4235 21.8997 21.1183 22.0261 20.8 22.0261H17V17.0001C17 15.674 16.4732 14.4023 15.5355 13.4646C14.5979 12.5269 13.3261 12.0001 12 12.0001C10.6739 12.0001 9.40215 12.5269 8.46447 13.4646C7.52678 14.4023 7 15.674 7 17.0001V22.0261H3.2C2.88174 22.0261 2.57652 21.8997 2.35147 21.6746C2.12643 21.4496 2 21.1444 2 20.8261V11.3191L12 2.31912L22 11.3191V20.8261Z"
    />
  </svg>
</template>
