<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 13.8753V9.36673C0 7.70849 1.34405 6.36197 2.99982 6.36197H4.24917C3.12459 4.9648 3.04924 2.87254 4.22158 1.45066C5.54216 -0.15117 7.8226 -0.326177 9.64597 0.437265C10.5721 0.825574 11.3553 1.41483 12.0001 2.13133C12.645 1.41483 13.4282 0.825574 14.3543 0.437265C16.1776 -0.326177 18.4581 -0.15117 19.7787 1.45066C20.9506 2.87295 20.8756 4.9648 19.7511 6.36197H21.0004C22.6562 6.36197 24.0002 7.70849 24.0002 9.36673V13.8753H22.0002V23.9994C15.3339 23.9994 8.66716 23.9994 2.00084 23.9994V13.8753H0.000823562H0ZM19.9994 13.8753H15.3281V21.9961H19.9994V13.8753ZM4.00004 21.9961H8.67128V13.8753H4.00004V21.9961ZM13.3277 21.9961V13.8753H10.6713V21.9961H13.3277ZM21.9994 11.872V9.36673C21.9994 8.81412 21.5514 8.36528 20.9996 8.36528H2.99982C2.44804 8.36528 2.00002 8.81412 2.00002 9.36673V11.872H21.9994ZM12.9999 4.11612V6.30679C16.302 6.27138 17.9322 6.00413 18.4956 4.53943C19.3076 1.75538 15.2643 0.777396 12.9999 4.11612ZM10.9999 6.30679V4.11612C8.73552 0.777396 4.69224 1.75538 5.50428 4.53943C6.06759 6.00413 7.69783 6.27138 10.9999 6.30679Z"
    />
  </svg>
</template>
